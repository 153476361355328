<template>
  <div class="exchange-style">
    <titles :title="$t('text.t348')" />
    <div class="outbox center-center">
      <el-card class="box-card" v-loading="isloading">
        <div class="box1 between-center">
          <div class="b1-t1"></div>
          <div class="b1-t2">{{$t('text.t348')}}</div>
          <img
            src="@/assets/img/img32.png"
            alt=""
            class="b1-t1"
            @click="goAhead('/exchangelist')"
          />
        </div>
        <van-divider>{{$t('text.t349')}}</van-divider>
        <div class="box3 between-center b3-mb20">
          <input
            disabled
            class="b3-input"
            :placeholder="$t('text.t350')"
            v-model="ids"
          />
          <div class="b3-t1" @click="goAhead('/choosenft')">{{$t('text.t351')}}</div>
        </div>
        <div class="box3 between-center b3-mb15">
          <input disabled class="b3-input" v-model="core.total" />
          <div class="b3-t2">OMT</div>
        </div>
        <div class="box6 center-center" @click="exchangeOmt">
          {{ $t("text.t135") }}
        </div>
        <div class="box5">{{$t('text.t352')}}</div>
      </el-card>
    </div>
    <div class="outbox center-center">
      <el-card class="box-card">
        <div class="box8">
          <div class="between-center b3-mb15">
            <div>{{$t('text.t353')}}</div>
            <div>{{ core.number }}{{$t('text.t153')}}</div>
          </div>
          <div class="between-center b3-mb15">
            <div>{{$t('text.t354')}}</div>
            <div>{{ core.exchange_day }}{{$t('text.t185')}}</div>
          </div>
          <div class="between-center">
            <div>{{$t('text.t429')}}</div>
            <div>{{ core.exchange_price }} USDT</div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components: {
    titles,
  },
  data() {
    return {
      omt_num: "",
      isloading: false,
      info: {},
      ids: "",
      core: {
        total: "0",
      },
      tmsg: "",
    };
  },
  created() {
    this.getCore();
  },
  computed: {
    ...mapState(["config", "language"]),
  },
  mounted() {
    this.$bus.$on("chooseNfts", (e) => {
      this.info = e;
      this.ids = `True NFT #${this.info.tokenid}`;
      console.log(this.ids);
    });
  },
  methods: {
    getCore() {
      this.$http.get("/release/core").then((res) => {
        this.core = res.data;
      });
    },
    async exchangeOmt() {
      if(!this.ids){
        this.$toast(this.$t('text.t350'));
        return
      }
      try {
        this.isloading = true;
        let arg = {
          id: this.info.id,
          language: this.language,
        };
        const res = await this.$http.post("/release/addOrder", arg);
        const { tokenid, address, orderno } = res.data;
        const result = await this.checkAllowance();
        console.log(result);
        this.shardsCollect(tokenid, address, orderno);
      } catch (error) {
        console.log(error);
        this.isloading = false;
      }
    },
    async checkAllowance() {
      const user_address = this.$local.get("shop:account");
      const web3 = new this.Web3(ethereum);
      const contract_from = this.config.true; // 授权的合约地址
      const abi_from = JSON.parse(this.config.true_abi);
      const contract_to = this.config.exchange_nft; // 授权给的合约地址

      const contract_proto = new web3.eth.Contract(abi_from, contract_from, {
        from: user_address,
      });
      const isAllowance = await contract_proto.methods
        .isApprovedForAll(user_address, contract_to)
        .call();
      if (isAllowance) {
        return true;
      } else {
        const result1 = await contract_proto.methods
          .setApprovalForAll(contract_to, true)
          .send();
        console.log(result1);
        return true;
      }
    },
    shardsCollect(tokenid, address, orderno) {
      const web3 = new this.Web3(ethereum);
      const contract_to = this.config.exchange_nft;
      const abi_to = JSON.parse(this.config.exchange_nft_abi);
      const nft_contract = new web3.eth.Contract(abi_to, contract_to, {
        from: address,
      });
      nft_contract.methods
        .exchange(Number(tokenid))
        .send()
        .on("transactionHash", async (hash) => {
          const rest = await this.sendHash(hash, orderno);
          this.tmsg = "";
          this.tmsg = rest.msg;
        })
        .on("receipt", async (receipt) => {
          console.log(receipt);
          this.isloading = false;
          this.$toast(this.tmsg);
          this.ids = ''
          await this.$http.post('/collections/userAsset721')
          this.getCore();
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.isloading = false;
        });
    },
    async sendHash(hash, orderno) {
      const arg = {
        hash,
        orderno,
        language: this.language,
      };
      return await this.$http.post("/release/addHash", arg);
    },
  },
  beforeRouteLeave(to, from, next) {
    const whitelist = ["Choosenft", "Exchangelist"];
    console.log(whitelist.includes(to.name));
    if (whitelist.includes(to.name)) {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
    }
    next();
  },
};
</script>

<style lang="less">
.exchange-style {
  .outbox {
    padding: 15px 0 20px;
    .box-card {
      width: 350px;
      border-radius: 20px;
      .van-divider {
        font-size: 9px;
        margin: 11px auto 26px;
      }
      .box1 {
        padding: 0 20px;
        .b1-t1 {
          width: 22px;
          height: auto;
        }
        .b1-t2 {
          font-size: 24px;
          font-weight: bold;
          color: #323232;
        }
      }
      .box2 {
        margin-bottom: 12px;
        .b2-i1 {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
        .b2-t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .b2-t2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
      .box3 {
        height: 50px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #2b2b2b;
        padding: 0 20px;
        border: 1px solid #2b2b2b;
        .b3-input {
          height: 26px;
          width: 80%;
          border: none;
          background-color: transparent;
          font-size: 12px;
          font-weight: 600;
          color: #909090;
        }
        .b3-t1 {
          font-size: 12px;
          font-weight: 500;
          color: #0f7aff;
        }
        .b3-t2 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .b3-mb20 {
        margin-bottom: 20px;
      }
      .b3-mb15 {
        margin-bottom: 15px;
      }
      .box4 {
        margin-bottom: 21px;
        .box4-i {
          width: 52px;
          height: 52px;
        }
      }
      .box5 {
        font-size: 12px;
        font-weight: bold;
        color: #909090;
      }
      .box6 {
        width: 304px;
        height: 45px;
        background: #2b2b2b;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #b2b2b2;
        margin: 0 auto 15px;
        font-size: 18px;
        font-weight: bold;
        color: #ffffff;
      }
      .box7 {
        width: 304px;
        height: 45px;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 4px -3px 0 #2b2b2b;
        margin: 0 auto 30px;
        font-size: 18px;
        font-weight: bold;
        color: #323232;
        border: 1px solid #323232;
        .b7-i1 {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .box8 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
}
</style>